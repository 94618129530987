// const React = require("react");
// import "tailwindcss/base.css"
// import "tailwindcss/components.css"
// import "tailwindcss/utilities.css"

import "@fontsource/poppins"
import './src/styles/global.css'
import React from 'react';
import { ParallaxProvider } from 'react-scroll-parallax';

export const wrapRootElement = ({ element }) => {
	return (
	  <ParallaxProvider>
		{element}
	</ParallaxProvider>
	);
  }

// Logs when the client route changes
// module.exports.onRouteUpdate = ({ location, prevLocation }) => {
// //   console.log("new pathname", location.pathname)
// //   console.log("old pathname", prevLocation ? prevLocation.pathname : null)
// }

// module.exports.onInitialClientRender = () => {
//     if (typeof window !== 'undefined') {
//         window.addEventListener('popstate', () => {
//             const newLink = window.location.href;
//             console.log(newLink);
//             if (newLink.indexOf("/team") > -1) {
//                 return window.location.href = newLink;
//             }
//             return null;
//         }
//         )
//     }
//   }

// Wraps every page in a component
// exports.wrapPageElement = ({ element, props }) => {
//   return <Layout {...props}>{element}</Layout>
// }